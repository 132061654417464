const ACTIVATION_STATUS = {
    NOT_ASSIGNED: 'NOT_ASSIGNED',
    ONBOARDING: 'ONBOARDING',
    ACTIVE: 'ACTIVE',
    DEACTIVATED: 'DEACTIVATED',
};

const COMPANY_PAYMENT_METHOD_STATUS = {
    BANK_TRANSFER: 'BANK_TRANSFER',
    SDD_STRIPE_NOTIFICATION_TO_SEND: 'SDD_STRIPE_NOTIFICATION_TO_SEND',
    SDD_STRIPE_NOTIFICATION_SENT: 'SDD_STRIPE_NOTIFICATION_SENT',
    SDD_STRIPE_PROCESSING: 'SDD_STRIPE_PROCESSING',
    SDD_STRIPE_ERROR: 'SDD_STRIPE_ERROR',
    SDD_STRIPE_GRANTED: 'SDD_STRIPE_GRANTED',
};

const COMPANY_PAYMENT_METHOD_STATUS_CHOICES = [
    {display_name: 'Bonifico bancario', value: COMPANY_PAYMENT_METHOD_STATUS.BANK_TRANSFER},
    {display_name: 'SDD - da inviare', value: COMPANY_PAYMENT_METHOD_STATUS.SDD_STRIPE_NOTIFICATION_TO_SEND},
    {display_name: 'SDD - inviato', value: COMPANY_PAYMENT_METHOD_STATUS.SDD_STRIPE_NOTIFICATION_SENT},
    {display_name: 'SDD - in accettazione', value: COMPANY_PAYMENT_METHOD_STATUS.SDD_STRIPE_PROCESSING},
    {display_name: 'SDD - errore', value: COMPANY_PAYMENT_METHOD_STATUS.SDD_STRIPE_ERROR},
    {display_name: 'SDD - accettato', value: COMPANY_PAYMENT_METHOD_STATUS.SDD_STRIPE_GRANTED},
];

export {ACTIVATION_STATUS, COMPANY_PAYMENT_METHOD_STATUS_CHOICES, COMPANY_PAYMENT_METHOD_STATUS};
