import {ApiBase} from '@jetCommon/api/base.js';

export default class ToolsApi extends ApiBase {
    static resourceName = 'tools';

    validateUnilav(unilavFile) {
        const form = new FormData();
        form.append('file', unilavFile);
        return this.apiPost('unilav/validate/', form);
    }

    notifyLabourConsultant({labour_consultant, company, unilav_file, send_before, message}) {
        const form = new FormData();
        form.append('labour_consultant', labour_consultant);
        form.append('company', company);
        form.append('unilav_file', unilav_file);
        form.append('send_before', send_before ?? '');
        form.append('message', message ?? '');

        return this.apiPost('unilav/notify_labour_consultant/', form);
    }
}
