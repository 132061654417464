/**
 * This file enumerates possible sections (menu items) in PayrollDetailMenu.vue
 * which occours in cicli-paghe/dettaglio pages
 * @type {Object} PAYROLL_DETAIL_SECTION
 * @type {string} PAYROLL_DETAIL_SECTION.DECLARATIONS
 * @type {string} PAYROLL_DETAIL_SECTION.EXPORT_JET_2_JOB
 * @type {string} PAYROLL_DETAIL_SECTION.IMPORT_JOB_2_JET
 * @type {string} PAYROLL_DETAIL_SECTION.PAYSLIPS_COMPARE
 * @type {string} PAYROLL_DETAIL_SECTION.SITUATION
 *
 * @see PayrollDetailMenu.vue
 */
export const PAYROLL_DETAIL_SECTION = {
    DECLARATIONS: 'DECLARATIONS',
    EXPORT_JET_2_JOB: 'EXPORT_JET_2_JOB',
    IMPORT_JOB_2_JET: 'IMPORT_JOB_2_JET',
    PAYSLIPS_COMPARE: 'PAYSLIPS_COMPARE',
    SITUATION: 'SITUATION',
};
