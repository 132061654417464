import CompanyOnboardingTaskCommon from '@jetCommon/api/company-onboarding-task.js';

export default class CompanyOnboardingTaskApi extends CompanyOnboardingTaskCommon {
    setup(id, data) {
        return this.apiPost(`${id}/setup/`, data);
    }

    submitData(id, data) {
        return this.apiPost(`${id}/submit_data/`, data);
    }

    approval(id, data) {
        return this.apiPost(`${id}/approval/`, data);
    }

    markAsCompleted(id) {
        return this.apiPost(`${id}/mark_as_completed/`);
    }

    markAsUncompleted(id) {
        return this.apiPost(`${id}/mark_as_uncompleted/`);
    }
}
