import {ApiBase} from '@jetCommon/api/base.js';

export default class LabourConsultantApi extends ApiBase {
    static resourceName = 'labour_consultants';

    uploadAvatar(labourConsultantId, data) {
        return this.apiPostForm(`${labourConsultantId}/avatar/`, data);
    }

    removeAvatar(labourConsultantId) {
        return this.apiDelete(`${labourConsultantId}/avatar/`);
    }
}
