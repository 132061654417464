import {ApiBase} from '@jetCommon/api/base.js';

export default class CompanyNoteApi extends ApiBase {
    static resourceName = 'company_notes';

    /**
     * Returns attachment's note url
     * @param {String} noteId
     * @param {String} attachmentId
     * @returns {String}
     */
    downloadAttachment(noteId, attachmentId) {
        return this.getApiUrlWithParams(`${noteId}/download_attachment/`, {attachment_id: attachmentId});
    }

    /**
     * Returns zip file url with all attachments of the note
     * @param {String} noteId
     * @returns {String}
     */
    downloadAttachments(noteId) {
        return this.getApiUrlWithParams(`${noteId}/download_zipped_attachments/`);
    }

    /**
     * Create a new note attachment
     * @param {String} noteId -> Id of the note to attach the file to
     * @param {{ uploaded_file_id: number }} data -> Id of the uploaded file to attach
     * @returns {void}
     */
    createAttachment(noteId, data) {
        return this.apiPost(`${noteId}/create_attachment/`, data);
    }

    /**
     * Delete a note attachment
     * @param {String} attachmentId -> Id of the attachment to delete
     * @returns {void}
     */
    deleteAttachment(attachmentId) {
        return this.apiDelete(`delete_attachment/`, {
            data: {attachment_id: attachmentId},
        });
    }

    /**
     * Get Payroll choices
     * @param {Object} params
     * @returns {Promise<[{ display_name: string, value: string | number }]>}
     */
    getPayrollChoices(params) {
        return this.apiGet('payroll_choices/', {params});
    }

    /**
     * Get Person choices
     * @param {Object} params
     * @returns {Promise<[{ display_name: string, value: string | number }]>}
     */
    getPersonChoices(params) {
        return this.apiGet('created_by_choices/', {params});
    }
}
